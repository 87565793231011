import { autoCompleteStore } from "app/core/stores/AutoCompleteStore"
import { reviewPageStore } from "app/core/stores/ReviewStore"
import { lawyerStore, matterStore, whoAmIStore } from "app/core/stores/store"
import { useSession } from "blitz"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { LoadingSpinner } from "../spinner/LoadingSpinner"

export const PreLoader = observer((props: { children }) => {
  const { children } = props
  const session = useSession()

  useEffect(() => {
    if (session.userInOrgId) {
      whoAmIStore.load(session.userInOrgId)
      matterStore.load()
      lawyerStore.load()
      autoCompleteStore.load()
      reviewPageStore.load()
    }
  }, [session.userInOrgId])

  if (
    !reviewPageStore.finishLoad ||
    !matterStore.finishLoad ||
    !lawyerStore.finishLoad ||
    !whoAmIStore.finishLoad ||
    !autoCompleteStore.finishLoad
  ) {
    return <LoadingSpinner center />
  } else {
    return children
  }
})
