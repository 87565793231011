import { action, makeObservable, observable } from "mobx"

export class EmailStore {
  selected?: number

  constructor() {
    makeObservable(this, {
      selected: observable,
      setSelected: action,
      removeSelected: action,
    })
  }

  setSelected = (key: number): void  => {
    this.selected = key
  }
  removeSelected = (): void  => {
    this.selected = undefined
  }
}
