import { Client, Entry, Matter, UserInOrganization } from "db"
import _ from "lodash"
import { action, makeObservable, observable } from "mobx"
import moment from "moment"
import { getBillingDetails } from "../utils/billing"
import { matterStore } from "./store"

type StatType = { billableHours: number; amount: number; rate: number; totalHours: number }
type DayStatType = { [k: string]: StatType }

export interface AugmentedStatistic {
  statistics: StatType
  days: DayStatType
  range: Date[]
}

export interface StatisticBody {
  id: number
  matter: Matter
  billableHours: number
  totalHours: number
  amount: number
  rate: number
}

/**
 * L > C > M > D
 */

export interface AugmentedLawyer extends UserInOrganization, AugmentedStatistic {
  __data: Map<number, AugmentedClient>
}

export interface AugmentedClient extends Client, AugmentedStatistic {
  __data: Map<number, AugmentedMatter>
}

export interface AugmentedMatter extends Matter, AugmentedStatistic {
  __data: Map<number, AugmentedEntry>
}

export interface AugmentedEntry extends Entry, AugmentedStatistic {}

/**
 * Get the next map from an augmented map.
 * @param augmented
 * @returns
 */

export const reportsByX = (
  augmented: AugmentedClient[] | AugmentedLawyer[] | AugmentedMatter[]
) => {
  return augmented.map((l) => Array.from(l.__data.values())).flat(1) as any
}

export type StatisticPeriod = "daily" | "monthly" | "weekly"

export type StatisticType = "full" | "minimal"

export const organizeDockets = (
  entries: Entry[],
  referenceLawyers: UserInOrganization[],
  range: Date[]
) => {
  const { map, augmentedEntries } = organizeDocketsByLawyer(entries, referenceLawyers, range)
  const toReport = organizeDocketsToReport(map, referenceLawyers, range)
  return { toReport, augmentedEntries }
}

export const organizeDocketsByLawyer = (
  entries: Entry[],
  referenceLawyers: UserInOrganization[],
  range: Date[]
) => {
  const map = new Map<number, Map<number, AugmentedEntry>>()

  /**
   * Create lawyerId > entryId > Entry
   */

  const augmentedEntries: AugmentedEntry[] = []

  entries.forEach((e) => {
    if (e.lawyerId && e.matterId) {
      const billingDetails = getBillingDetails(
        e,
        referenceLawyers,
        matterStore.matterArray,
        matterStore.clientArray
      )

      const statistics = {
        amount: billingDetails[1] ?? 0,
        // TODO: this should not be hardcoded
        rate: e.task === "nbw" ? 0 : billingDetails[2] ?? 0,
        billableHours: billingDetails[0] ?? 0,
        totalHours: billingDetails[3] ?? 0,
      }

      const organizeByDay = {}
      // Standardize a date format. Strip time.
      organizeByDay[moment(e.date).format("MMM-D-YYYY")] = statistics

      const aug: AugmentedEntry = {
        ...e,
        statistics: statistics,
        days: organizeByDay,
        range: range,
      }

      augmentedEntries.push(aug)

      if (map.has(e.lawyerId)) {
        map.get(e.lawyerId)?.set(e.id, aug)
      } else {
        const _new = new Map()

        _new.set(e.id, aug)
        map.set(e.lawyerId, _new)
      }
    }
  })
  return { map, augmentedEntries }
}

const mergeDays = (a: DayStatType, b: DayStatType) => {
  // A overwrites b here.
  const roughMerge = _.cloneDeep(a)
  // We need to now manually add entries from b.
  const keys = Object.keys(b)
  keys.forEach((k) => {
    if (!roughMerge[k]) {
      roughMerge[k] = { amount: 0, billableHours: 0, totalHours: 0, rate: 0 }
    }
    roughMerge[k].amount += b[k].amount
    roughMerge[k].billableHours += b[k].billableHours
    roughMerge[k].totalHours += b[k].totalHours
    roughMerge[k].rate = roughMerge[k].amount / roughMerge[k].billableHours
  })
  return _.cloneDeep(roughMerge)
}

const augmentMapInsert = (parent, parentMap, childId, childMap, range) => {
  if (parent) {
    const child = childMap.get(childId)!
    if (parentMap.has(parent.id)) {
      const aug = parentMap.get(parent.id)!

      const summationBillableHours = child.statistics.billableHours + aug.statistics.billableHours
      const summationTotalHours = child.statistics.totalHours + aug.statistics.totalHours
      const sumAmount = child.statistics.amount + aug.statistics.amount
      aug.statistics = {
        totalHours: _.round(summationTotalHours, 1),
        billableHours: _.round(summationBillableHours, 1),
        amount: _.round(sumAmount, 2),
        rate: _.round(
          sumAmount > 0 && summationBillableHours > 0 ? sumAmount / summationBillableHours : 0,
          2
        ),
      }
      aug.range = range
      aug.days = mergeDays(child.days, aug.days)
      aug.__data.set(childId, child)
      parentMap.set(parent.id, aug)
    } else {
      const aug = {
        ...parent,
        statistics: {
          range: range,
          billableHours: child.statistics.billableHours,
          totalHours: child.statistics.totalHours,
          amount: child.statistics.amount,
          rate: child.statistics.rate,
        },
        range: range,
        days: _.cloneDeep(child.days),
        __data: new Map(),
      }
      aug.__data.set(childId, child)
      parentMap.set(parent.id, aug)
    }
  }
  return parentMap
}

export const reportsByXMerge = (augmented: any[], clientMerge = false) => {
  const map = new Map<number, any>()
  augmented.forEach((a) => {
    if (map.has(a.id)) {
      const current = map.get(a.id)
      const currentStats = current?.statistics
      const newStats = {
        amount: currentStats!.amount + a.statistics.amount,
        totalHours: currentStats!.totalHours + a.statistics.totalHours,
        billableHours: currentStats!.billableHours + a.statistics.billableHours,
        rate: currentStats!.rate,
      }
      const newDays = mergeDays(current!.days, a.days)
      const newRange = current!.range

      let newData = current.__data!

      if (clientMerge) {
        const currentMatters = Array.from(current.__data.values())
        const similarMatters = Array.from(a.__data.values())

        // Recursively merge matters...
        newData = reportsByXMerge([...currentMatters, ...similarMatters])
      } else {
        for (let d of Array.from(a.__data.entries())) {
          const day: any = d
          newData.set(day[0], day[1])
        }
      }

      map.set(a.id, {
        ...current!,
        __data: newData,
        statistics: newStats,
        days: newDays,
        range: newRange,
      })
    } else {
      map.set(a.id, a)
    }
  })
  return map
}

const organizeDocketsToReport = (
  input: Map<number, Map<number, AugmentedEntry>>,
  referenceLawyers: UserInOrganization[],
  range: Date[]
) => {
  let lawyerMap: Map<number, AugmentedLawyer> = new Map<number, AugmentedLawyer>()

  /**
   * Map<number, Map<number, Entry>> (lawyerId, entryId, Entry)
   * Gets converted into:
   * Map<number, AugmentedMatter> (matterId, augMatter)
   */

  const lawyerIds = Array.from(input.keys())
  lawyerIds.forEach((lawyerId) => {
    const docketMap = input.get(lawyerId)
    const dockets = Array.from(docketMap?.values() ?? [])

    let matterMap = new Map<number, AugmentedMatter>()

    dockets.forEach((d) => {
      const matter = matterStore.findMatterFromId(d.matterId!)
      matterMap = augmentMapInsert(matter, matterMap, d.id, docketMap, range)
    })

    let clientMap = new Map<number, AugmentedClient>()
    const matters = Array.from(matterMap.keys())

    matters.forEach((matterId) => {
      const client: Client | undefined = matterStore.findClientFromMatter(matterId)
      clientMap = augmentMapInsert(client, clientMap, matterId, matterMap, range)
    })

    const clients = Array.from(clientMap.keys())

    clients.forEach((clientId) => {
      const lawyer: UserInOrganization | undefined = referenceLawyers.find((l) => l.id === lawyerId)
      lawyerMap = augmentMapInsert(lawyer, lawyerMap, clientId, clientMap, range)
    })
  })
  return lawyerMap
}

export class StatisticStore {
  amounts: Map<number, StatisticBody> = new Map()

  reports: Map<number, AugmentedLawyer>

  reportsCount = 0

  constructor() {
    makeObservable(this, {
      amounts: observable,
      reports: observable,
      period: observable,
      reportsCount: observable,
      type: observable,
      setPeriod: action,
      setType: action,
    })
  }

  reportsByMatter = () => {
    const clients = this.reportsByClient()
    // console.log(clients)
    return reportsByX(clients)
  }

  reportsByClient = () => {
    const lawyers = this.reportsByLawyer()
    // Only reportsByClient requires reportsByXMerge because it is recursive...
    return Array.from(reportsByXMerge(reportsByX(lawyers), true).values())
  }

  reportsByLawyer = () => {
    const data = Array.from(this.reports.values())
    return data
  }

  /**
   * @pre Needs clientsWithMatters to be preloaded
   * @param input
   * @param lawyers
   */
  generateReports = (entries: Entry[], lawyers: UserInOrganization[], range: Date[]): void => {
    const { map } = organizeDocketsByLawyer(entries, lawyers, range)
    const toReport = organizeDocketsToReport(map, lawyers, range)
    this.reports = toReport
    this.reportsCount += 1
  }

  /**
   * Preferences
   */

  period: StatisticPeriod = "daily"
  type: StatisticType = "minimal"
  initializePreferences(): void {
    //   // Initialize variables in this. This has no effect on the downstream function.
    this.period = (localStorage.getItem("StatisticPeriod") ?? "daily") as StatisticPeriod
    this.type = (localStorage.getItem("StatisticType") ?? "minimal") as StatisticType
  }

  setType = (type: StatisticType): void => {
    localStorage.setItem("StatisticType", type)
    this.type = type
  }

  setPeriod = (period: StatisticPeriod): void => {
    localStorage.setItem("StatisticPeriod", period)
    this.period = period
  }
}
