import * as Sentry from "@sentry/nextjs"
import getUserInOrganization from "app/user-in-organizations/queries/getUserInOrganization"
import { invoke } from "blitz"
import { Organization, UserInOrganization } from "db"
import { H } from "highlight.run"
import { cloneDeep } from "lodash"
import { action, makeObservable, observable } from "mobx"

export class WhoAmIStore {
  me: UserInOrganization & {
    organization: Organization
  }

  initialLoad: boolean = false
  finishLoad: boolean = false

  constructor() {
    makeObservable(this, {
      load: action,
      finishLoad: observable,
      initialLoad: observable,
      me: observable,
      setFinishLoad: action,
      setInitialLoad: action,
    })
  }

  setFinishLoad = (): void => {
    this.finishLoad = true
  }

  setInitialLoad = (): void => {
    this.initialLoad = true
  }

  load = async (userId) => {
    if (!this.initialLoad) {
      this.setInitialLoad()

      const r: UserInOrganization & {
        organization: Organization
      } = cloneDeep(await invoke(getUserInOrganization, { id: userId }))

      if (process.env.NODE_ENV !== "development") {
        const user = {
          email: r.invitedEmail,
          id: r.id.toString(),
          role: r.role,
          organization: r.organizationId,
          name: r.invitedName,
        }
        // highlight.run
        H.identify(r.invitedEmail, user)
        Sentry.setUser(user)
      }

      this.me = r
      this.setFinishLoad()
    }
  }
}
