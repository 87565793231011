import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"
import { theme } from "app/core/styles/styles"
import React from "react"

export const ButtonLoadingSpinner = () => {
  const style = {
    // left: 0,
    // right: 0,
    // marginTop: 100,
    position: "relative",
    marginLeft: 8,
    marginRight: 0,
    width: 14,
    height: 14,
    color: theme.gray[550],
  }
  return <LoadingSpinner size={14} style={style} />
}

export const LoadingSpinner = (props: { style?: any; center?: boolean; size?: number }) => {
  let style = { ...props.style }
  const centerStyle = {
    position: "absolute",
    left: 0,
    right: 0,
    marginTop: 100,
    marginLeft: "auto",
    marginRight: "auto",
    width: 100,
    color: theme.gray[550],
  }
  if (props.center) {
    style = { ...style, ...centerStyle }
  }
  const antIcon = (
    <LoadingOutlined
      spin
      style={{ fontSize: props.size ?? 24, position: "absolute", top: 0, left: 0 }}
    />
  )
  return <Spin indicator={antIcon} style={{ ...style }} />
}

export const FullWidthLoadingSpinner = (props: {
  style?: any
  center?: boolean
  size?: number
}) => {
  let style = { ...props.style }
  const centerStyle = {
    position: "relative",
    margin: "0 auto",
    width: 100,
    color: theme.gray[550],
  }
  if (props.center) {
    style = { ...style, ...centerStyle }
  }
  const antIcon = (
    <LoadingOutlined
      spin
      style={{ fontSize: props.size ?? 24, position: "absolute", top: 0, left: 0 }}
    />
  )
  return <Spin indicator={antIcon} style={{ ...style }} />
}
