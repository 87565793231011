import { Client, Entry, Matter, Prisma, UserInOrganization } from "db"
import _ from "lodash"
import moment from "moment"
import { formatHours } from "../components/table/cells/utility/time"
import { lawyerStore, matterStore } from "../stores/store"

export const getBillingDetails = (
  entry: Entry,
  lawyers: UserInOrganization[],
  matters: Matter[],
  clients: Client[]
) => {
  const rate: number | undefined = getRateFromEntry(entry, lawyers, matters, clients, entry.date)
  let totalHours = _.round(formatHours([entry]), 1)
  let billableHours = 0
  if (entry.task === "bw") {
    billableHours = totalHours
  }
  const amount = _.round((rate ?? 0) * billableHours, 2)
  return [billableHours, amount, rate, totalHours]
}

export const rateFromEntry = (entry: Entry) => {
  return getRateFromEntry(
    entry,
    lawyerStore.lawyersArray,
    matterStore.matterArray,
    matterStore.clientArray,
    entry.date
  )
}

export const getRateFromEntry = (
  entry: Entry,
  lawyers: UserInOrganization[],
  matters: Matter[],
  clients: Client[],
  date
): number => {
  const lawyerRate = getRateFromLawyer(lawyers.find((l) => l.id === entry.lawyerId)!, date)
  let rate = lawyerRate ?? 0
  if (entry.matterId) {
    const matter = matters.find((m) => m.id === entry.matterId)
    if (matter) {
      const matterRate = getRateFromMatter(matter, entry.lawyerId, date)
      if (matterRate) {
        rate = matterRate
      } else {
        const client = clients.find((c) => c.id === matter.clientId)
        if (client) {
          const clientRate = getRateFromClient(client, entry.lawyerId, date)
          if (clientRate) {
            rate = clientRate
          }
        }
      }
    }
  }

  // Always return an int.
  return typeof rate === "string" ? parseFloat(rate) : rate
}

export const getRateFromLawyer = (lawyer: UserInOrganization, date): number | undefined => {
  if (lawyer && lawyer.rates && (lawyer.rates as Prisma.JsonArray).length > 0) {
    const rates = lawyer.rates as Prisma.JsonArray
    return getRate(rates, date)
  } else {
    return undefined
  }
}

export const getRateFromClient = (client: Client, lawyerId: number, date) => {
  if (
    client &&
    client.rates &&
    client.rateMode === "CUSTOM" &&
    (client.rates as Prisma.JsonArray).length > 0
  ) {
    const rates = client.rates as Prisma.JsonArray
    const _rates = rates.filter((r: any) => r.lawyer && r.lawyer === lawyerId)
    return getRate(_rates, date)
  } else {
    return undefined
  }
}

export const getRateFromMatter = (matter: Matter, lawyerId: number, date) => {
  if (
    matter &&
    matter.rates &&
    matter.rateMode === "CUSTOM" &&
    (matter.rates as Prisma.JsonArray).length > 0
  ) {
    const rates = matter.rates as Prisma.JsonArray
    const _rates = rates.filter((r: any) => r.lawyer && r.lawyer === lawyerId)
    return getRate(_rates, date)
  } else {
    return undefined
  }
}

export const getRate = (rates: Prisma.JsonArray, date) => {
  const sorted: any = rates
    .filter((f: any) => moment(date).isSameOrAfter(f.starting))
    .sort((a: any, b: any) => moment(b.starting).diff(moment(a.starting)))
  if (sorted.length > 0) {
    const rate = sorted[0].rate
    // Always return an int
    return typeof rate === "string" ? parseFloat(rate) : rate
  } else {
    return undefined
  }
}

export const averageRate = (): void => {}
