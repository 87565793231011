import { DownloadStore } from "./DownloadStore"
import { EmailStore } from "./EmailStore"
import { LawyerStore } from "./global/LawyerStore"
import { WhoAmIStore } from "./global/WhoAmIStore"
import { MatterStore } from "./MatterStore"
import { ModalStore } from "./ModalStore"
import { StatisticStore } from "./StatisticStore"
import { TemplateStore } from "./TemplateStore"
import { ThreadStore } from "./ThreadStore"
import { UploadStore } from "./UploadStore"

// Emails

export const emailDisplayStore = new EmailStore()

// Modals

export const modalStore = new ModalStore()
export const threadStore = new ThreadStore()

// Global Matter List

export const matterStore = new MatterStore()

// Global Lawyer List

export const lawyerStore = new LawyerStore()

// download

export const downloadStore = new DownloadStore()

// upload

export const uploadStore = new UploadStore()

// templates

export let templateStore: TemplateStore = new TemplateStore()

// export function initClientStores() {
//   templateStore = new TemplateStore()
// }

// StatisticStore

export const statisticStore = new StatisticStore()

export const reviewStore = new StatisticStore()

export const reportStore = new StatisticStore()

// Stores

export const whoAmIStore = new WhoAmIStore()
