import { action, makeObservable, observable } from "mobx"
import { ReactNode } from "react"

export class ModalStore {
  content?: ReactNode
  visible = false
  title = ""

  onExit: () => void

  constructor() {
    makeObservable(this, {
      content: observable,
      visible: observable,
      title: observable,
      setVisibility: action,
      setContent: action,
    })
  }

  setContent = (
    title: string,
    content: ReactNode,
    visible = true,
    onExit: () => void = () => {}
  ): void => {
    this.title = title
    this.onExit = onExit
    this.visible = visible
    this.content = content
  }

  setVisibility = (value: boolean): void => {
    if (value === false) {
      this.onExit()
    }
    this.visible = value
  }
}
