import getAllReviews from "app/reviews/queries/getAllReviews"
import { invoke } from "blitz"
import { Review } from "db"
import Dexie from "dexie"
import { cloneDeep, merge } from "lodash"
import { action, makeObservable, observable } from "mobx"

class ReviewStoreDatabase extends Dexie {
  public reviews: Dexie.Table<Review, number> // id is number in this case

  public constructor() {
    super("ReviewStoreDatabase")
    this.version(1).stores({
      reviews: "id",
    })
    this.reviews = this.table("reviews")
  }
}

const reviewStoreDatabase = new ReviewStoreDatabase()

export class ReviewStore {
  reviews: Review[] = []

  initialLoad: boolean
  finishLoad: boolean

  constructor() {
    makeObservable(this, {
      load: action,
      finishLoad: observable,
      initialLoad: observable,
      setFinishLoad: action,
      setInitialLoad: action,
      upsertReviewById: action,
    })
  }

  findReviewById(reviewId: number) {
    return this.reviews.find((c) => c.id === reviewId)
  }

  upsertReviewById(reviewId: number, review: Review | Partial<Review>) {
    const exists = this.reviews.find((c) => c.id === reviewId)
    if (exists) {
      merge(exists, review)
      reviewStoreDatabase.reviews.put(exists) // Update the dexie database.
    } else {
      this.reviews.push(cloneDeep(review as Review))
      reviewStoreDatabase.reviews.put(review as Review) // Update the dexie database.
    }
  }

  setFinishLoad = (): void => {
    this.finishLoad = true
  }

  setInitialLoad = (): void => {
    this.initialLoad = true
  }

  inMemoryInitialization = (reviews) => {
    this.reviews = cloneDeep(reviews)
  }

  load = async () => {
    if (!this.initialLoad) {
      this.setInitialLoad()

      const reviews = await reviewStoreDatabase.reviews.toArray()
      this.inMemoryInitialization(reviews)

      if (reviews.length > 0) {
        // Only set the load as finished iff there are cached clients.
        this.setFinishLoad()
      }

      setTimeout(async () => {
        const reviews = await invoke(getAllReviews, {})
        // TODO: URGENT: Delete missing autocompletes from the database.
        reviewStoreDatabase.reviews.bulkPut(reviews)
        this.inMemoryInitialization(reviews)
        this.setFinishLoad()
      }, 1500)
    }
  }
}

export const reviewPageStore = new ReviewStore()
