import getAllUserInOrganizations from "app/user-in-organizations/queries/getAllUserInOrganizations"
import { invoke } from "blitz"
import { UserInOrganization } from "db"
import Dexie from "dexie"
import { cloneDeep, merge } from "lodash"
import { action, computed, makeObservable, observable } from "mobx"

class LawyerStoreDatabase extends Dexie {
  public lawyers: Dexie.Table<UserInOrganization, number> // id is number in this case

  public constructor() {
    super("LawyerStoreDatabase")
    this.version(1).stores({
      lawyers: "id",
    })
    this.lawyers = this.table("lawyers")
  }
}

const lawyerStoreDatabase = new LawyerStoreDatabase()

export class LawyerStore {
  lawyersArray: UserInOrganization[] = []

  initialLoad: boolean
  finishLoad: boolean

  get activeLawyers() {
    return this.lawyersArray.filter((lawyer) => !lawyer.archived)
  }

  constructor() {
    makeObservable(this, {
      load: action,
      finishLoad: observable,
      initialLoad: observable,
      upsertLawyerById: action,
      setFinishLoad: action,
      setInitialLoad: action,
      lawyers: computed,
    })
  }

  getLawyers() {
    const flattened = Array.from(this.lawyers.values()).flat()
    return flattened
  }

  upsertLawyerById(lawyerId: number, lawyer: UserInOrganization | Partial<UserInOrganization>) {
    const exists = this.lawyersArray.find((c) => c.id === lawyerId)
    if (exists) {
      merge(exists, lawyer)
    } else {
      this.lawyersArray.push(cloneDeep(lawyer as UserInOrganization))
    }
  }

  findLawyerFromId(lawyerId: number) {
    return this.lawyers.get(lawyerId)
  }

  setFinishLoad = (): void => {
    this.finishLoad = true
  }

  setInitialLoad = (): void => {
    this.initialLoad = true
  }

  get lawyers() {
    const map: Map<number, UserInOrganization> = new Map()
    this.lawyersArray.forEach((c) => {
      map.set(c.id, c)
    })
    return map
  }

  inMemoryInitialization = (lawyers) => {
    this.lawyersArray = cloneDeep(lawyers)
  }

  load = async () => {
    if (!this.initialLoad) {
      this.setInitialLoad()

      const lawyers = await lawyerStoreDatabase.lawyers.toArray()
      this.inMemoryInitialization(lawyers)

      if (lawyers.length > 0) {
        // Only set the load as finished iff there are cached lawyers.
        this.setFinishLoad()
      }

      setTimeout(async () => {
        const lawyers = await invoke(getAllUserInOrganizations, {})
        // TODO: URGENT: Delete missing autocompletes from the database.
        lawyerStoreDatabase.lawyers.bulkPut(lawyers)
        this.inMemoryInitialization(lawyers)
        this.setFinishLoad()
      }, 1000)

      return this.lawyersArray
    }
  }
}
