import { Comment } from "db"
import { action, makeObservable, observable } from "mobx"

export class ThreadStore {
  visible = false
  entryId: number

  comments: Comment[] = []

  onExit: () => void = () => {}

  constructor() {
    makeObservable(this, {
      comments: observable,
      visible: observable,
      entryId: observable,
      addComment: action,
      setComments: action,
      setContent: action,
      setVisibility: action,
    })
  }

  addComment = (comment: Comment) => {
    this.comments.push(comment)
  }

  setComments = (comments): void => {
    this.comments = [...comments]
  }

  setContent = (entryId: number, visible: boolean = true, onExit: () => void = () => {}): void => {
    this.entryId = entryId
    this.onExit = onExit
    this.visible = visible
  }

  setVisibility = (value: boolean): void => {
    if (value === false) {
      this.onExit()
    }
    this.visible = value
  }
}
