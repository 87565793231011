import { Entry, Prisma } from "db"
import _ from "lodash"
import moment from "moment"

export const MAX_TIME = 60 * 60 * 24 - 1

export const getTimeGreeting = (): string => {
  let g: string | null = null //return g

  const split_afternoon = 12 //24hr time to split the afternoon
  const split_evening = 17 //24hr time to split the evening
  const currentHour = parseFloat(moment().format("HH"))

  if (currentHour >= split_afternoon && currentHour <= split_evening) {
    g = "Afternoon"
  } else if (currentHour >= split_evening) {
    g = "Evening"
  } else {
    g = "Morning"
  }

  return g
}

export const defaultRateObject = () => {
  return [
    {
      starting: moment().startOf("year").subtract(1, "year").toISOString(),
      rate: 100,
    } as Prisma.JsonObject,
  ] as Prisma.JsonArray
}

export function getTimeInSeconds(): number {
  return Math.floor(Date.now() / 1000)
}

function entryTotal(active: boolean, values: { start: number; stop: number }[]) {
  let seconds = 0
  for (let i = 0; i < values.length; i++) {
    // If the last element and the timer is counting, factor that into the calculation.
    if (i === values.length - 1 && active) {
      seconds += getTimeInSeconds() - values[i].start
      break
    }

    // If stop is not greater than start
    if (values[i].stop < values[i].start) {
      continue
    }

    seconds += values[i].stop - values[i].start
  }
  return seconds
}

// This should be applied to each individual entry. Before being summed. But not applied directly to the TimePicker.
export function roundTenth(seconds: number): number {
  const r = seconds % 360
  if (r !== 0) {
    const s = seconds + (360 - r)
    return s
  } else {
    return seconds
  }
}

type TimeObject = Entry // | { times: { start: number; stop: number }[] } TODO: We can add this once we convert from string to Json times objects...

function entriesTotal(entries: TimeObject[]) {
  const a = entries.map((v: TimeObject, i: number, arr: TimeObject[]) => {
    if (_.isNil(v) || _.isNil(v.times)) {
      return 0
    }
    const total = entryTotal(isRunning(JSON.parse(v.times)), JSON.parse(v.times))
    if (arr.length > 1) {
      // Only perform rounding when there is more than one entry being tallied.
      return roundTenth(total)
    }
    return total
  })
  const t = a.reduce((p: number, c: number) => {
    // Important to round here... Adds multiple different entries together...
    return p + c
  }, 0)
  return t
}

function formatSeconds(seconds: number, format: string) {
  return moment().startOf("day").seconds(seconds).format(format)
}

export function formatTime(entry: Entry, maximumTime?: number) {
  // Doesn't require a rounding because it is used in the TimePicker.
  let seconds = entriesTotal([entry])
  // Limit the number of seconds displayed here.
  if (seconds > (maximumTime ?? MAX_TIME)) {
    seconds = maximumTime ?? MAX_TIME
  }
  return formatSeconds(seconds, "H:mm:ss")
}

export const isRunning = (times: Array<any>): boolean => {
  const [lastItem] = times.slice(-1)

  if (
    lastItem &&
    lastItem.start !== undefined &&
    (lastItem.stop === undefined || lastItem.stop === -1)
  ) {
    return true
  } else {
    return false
  }
}

export const entryToMoment = (row: Entry, maximumTime?: number) => {
  const _sum = formatTime(row!, maximumTime)
  const _time = moment(_sum, "hh:mm:ss")
  return _time
}

// Decimal needs to be set.
export function hours(seconds: number) {
  return _.round(moment.duration(seconds, "seconds").asHours(), 1)
}

// Merge these getSeconds functions TODO
export function getStoppedSeconds(values: { start: number; stop: number }[]): number {
  // Format seconds always requires a rounding.
  const seconds = roundTenth(entryTotal(false, values))
  return seconds
}

export function getSeconds(values: Entry[]): number {
  // Format seconds always requires a rounding.
  const seconds = roundTenth(entriesTotal(values))
  return seconds
}

export function formatHours(entries: Entry[]) {
  return hours(getSeconds(entries))
}

export const timesArrayToSeconds = (values: { start: number; stop: number }[]) => {
  return entryTotal(false, values)
}

export function formatArray(values: { start: number; stop: number }[]) {
  return hours(roundTenth(timesArrayToSeconds(values)))
}

export function hoursToSecondsObject(value: number) {
  value = _.round(value, 1)
  const seconds = Math.trunc(value * 60 * 60)
  return [{ start: 0, stop: seconds ? seconds : 0 }]
}

export const roundedSeconds = (seconds: number) => {
  return roundTenth(seconds)
}
