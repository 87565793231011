import getAllAutoCompletes from "app/auto-completes/queries/getAllAutoCompletes"
import { invoke } from "blitz"
import { AutoComplete } from "db"
import Dexie from "dexie"
import { cloneDeep, merge } from "lodash"
import { action, makeObservable, observable } from "mobx"

class AutoCompleteStoreDatabase extends Dexie {
  public autocompletes: Dexie.Table<AutoComplete, number> // id is number in this case

  public constructor() {
    super("AutoCompleteStoreDatabase")
    this.version(1).stores({
      autocompletes: "id",
    })
    this.autocompletes = this.table("autocompletes")
  }
}

const autoCompleteStoreDatabase = new AutoCompleteStoreDatabase()

export class AutoCompleteStore {
  autocompletesArray: AutoComplete[] = []

  initialLoad: boolean
  finishLoad: boolean

  constructor() {
    makeObservable(this, {
      load: action,
      finishLoad: observable,
      initialLoad: observable,
      setFinishLoad: action,
      setInitialLoad: action,
    })
  }

  setFinishLoad = (): void => {
    this.finishLoad = true
  }

  setInitialLoad = (): void => {
    this.initialLoad = true
  }

  upsertAutoComplete(autocompleteId: number, autocomplete: AutoComplete | Partial<AutoComplete>) {
    const exists = this.autocompletesArray.find((c) => c.id === autocompleteId)
    if (exists) {
      merge(exists, autocomplete)
    } else {
      this.autocompletesArray.push(cloneDeep(autocomplete as AutoComplete))
    }
  }

  inMemoryInitialization = (autocompletes) => {
    this.autocompletesArray = cloneDeep(autocompletes)
  }

  load = async () => {
    if (!this.initialLoad) {
      this.setInitialLoad()

      const autocompletes = await autoCompleteStoreDatabase.autocompletes.toArray()
      this.inMemoryInitialization(autocompletes)

      if (autocompletes.length > 0) {
        // Only set the load as finished iff there are cached clients.
        this.setFinishLoad()
      }

      setTimeout(async () => {
        const autocompletes = await invoke(getAllAutoCompletes, {})
        // TODO: URGENT: Delete missing autocompletes from the database.
        autoCompleteStoreDatabase.autocompletes.bulkPut(autocompletes)
        this.inMemoryInitialization(autocompletes)
        this.setFinishLoad()
      }, 2500)
    }
  }
}

export const autoCompleteStore = new AutoCompleteStore()
