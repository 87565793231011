import { action, makeObservable, observable } from "mobx"

export class TemplateStore {
  templates: any[] = []

  //   addNew(record: Entry) {
  //     const ts: any[] = JSON.parse(localStorage.getItem("entryTemplates") ?? "[]")
  //     ts.push({
  //       lawyer: record.lawyerId,
  //       task: record.task,
  //       matter: record.matterId,
  //       description: record.description,
  //     })
  //     localStorage.setItem("entryTemplates", JSON.stringify(ts))
  //     this.templates = [...ts]
  //   }

  //   delete(index: number) {
  //     const ts: any[] = JSON.parse(localStorage.getItem("entryTemplates") ?? "[]")
  //     ts.splice(index, 1)
  //     localStorage.setItem("entryTemplates", JSON.stringify(ts))
  //     this.templates = [...ts]
  //   }

  constructor() {
    makeObservable(this, {
      templates: observable,
      setTemplates: action,
    })
  }

  setTemplates = (templates: Array<any>): void  => {
    this.templates = [...templates]
  }
}

// export {}
