import { action, makeObservable, observable } from "mobx"

export class DownloadStore {
  files: {
    id: string
    name: string
    progress: number
  }[] = []

  constructor() {
    makeObservable(this, {
      files: observable,
      setProgress: action,
      addFile: action,
    })
  }

  setProgress = (id: string, value: number): void  => {
    this.files.find((f) => f.id === id)!.progress = value
  }

  addFile = (row: { id: string; name: string; progress: number }): void  => {
    this.files = [...this.files, row]
  }
}
