import { Form, Select, Skeleton } from "antd"
import { matterStore } from "app/core/stores/store"
import updateMatter from "app/matters/mutations/updateMatter"
import { useSession } from "blitz"
import cuid from "cuid"
import { Matter } from "db"
import _, { invoke } from "lodash"
import { observer } from "mobx-react-lite"
import moment from "moment"
import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react"
import { LoadingSpinner } from "../spinner/LoadingSpinner"
const { Option, OptGroup } = Select

export const FormMatterSelect = (props: { onSelect?: any; initialValues?: any }) => {
  const { onSelect, initialValues } = props
  return (
    <MatterSearch
      defaultValue={initialValues?.matterId}
      disabled={false}
      formProps={{
        name: "matterId",
        label: "Matter",
        rules: [
          {
            required: true,
            message: "Please input!",
          },
        ],
        required: true,
      }}
      onSelect={(value) => onSelect?.(value.id)}
    />
  )
}

export const MatterSearch = forwardRef(
  (
    props: {
      onClose?: any
      style?: React.CSSProperties
      defaultValue?: number | null
      formProps?: any
      onSelect?: (matter: Matter) => void
      disabled?: boolean
      defaultOpen?: boolean
    },
    ref
  ) => {
    const [loading, setLoading] = useState<boolean>(false)
    const { disabled, defaultOpen } = props

    const load = useCallback(async () => {
      if (!loading) {
        if (!matterStore.initialLoad && !matterStore.finishLoad) {
          setLoading(true)
          await matterStore.load()
          setLoading(false)
        }
      }
    }, [loading])

    useEffect(() => {
      load()
    }, [load])

    return (
      <MatterSelect
        defaultOpen={defaultOpen}
        defaultValue={props.defaultValue}
        disabled={disabled ?? false}
        formProps={props.formProps}
        loading={loading}
        search={load}
        style={props.style}
        onClose={props.onClose}
        onSelect={props.onSelect}
      />
    )
  }
)

// Does not need to be an observer. Loading will tell it when to render...
const MatterSelect = observer(
  (props: {
    onClose?: any
    style?: React.CSSProperties
    defaultOpen?: boolean
    loading: boolean
    search: () => void
    defaultValue?: number | null
    onSelect?: (matter: Matter) => void
    formProps?: any
    disabled: boolean
  }) => {
    const { onClose, disabled, defaultOpen } = props
    const session = useSession()
    const isEmbedded = _.isNil(props.formProps)

    const ChildSelect = (props: any) => {
      const clientMatterArray = Array.from(matterStore.clientsWithOnlyActiveMatters.entries())
      // Matters selected within the last 30 days
      const recentMatters = Array.from(matterStore.clientsWithOnlyActiveMatters.values())
        .flat()
        .filter(
          (m) =>
            m.lastSelectedBy &&
            m.lastSelectedBy === session.userInOrgId &&
            moment(m.lastSelected).diff(moment(), "day") < 30
        )
      const matterRandomPrefixKey = cuid()
      const clientRandomPrefixKey = cuid()

      // Focus the element if defaultOpen is set to true. Only used in the table row... There may be a better way to implement this...
      const ref = useRef<any>()
      useEffect(() => {
        if (props.defaultOpen) {
          ref.current!.focus()
        }
      }, [props.defaultOpen])

      return (
        <Form.Item {...props.formProps} noStyle={_.isNil(props.formProps)}>
          <Select
            showSearch
            bordered={!isEmbedded}
            className={isEmbedded ? "selectable-cell-value-wrap" : ""}
            defaultOpen={defaultOpen}
            defaultValue={props.defaultValue}
            disabled={disabled}
            dropdownStyle={{ minWidth: 500, maxWidth: 500 }}
            loading={props.loading}
            notFoundContent={props.loading ? <LoadingSpinner center /> : null}
            optionFilterProp="search"
            placeholder="Please select the matter"
            ref={ref}
            style={{ minWidth: 200, maxWidth: "100%", ...props?.style }}
            onBlur={() => onClose?.()}
            onClick={props.search}
            onSelect={(value) => {
              if (value) {
                if (props.onSelect) {
                  props.onSelect(matterStore.matterArray.find((m) => m.id === value))
                }
                // Use the manual invokation instead of the hook to prevent rerendering...
                invoke(updateMatter, {
                  id: value as number,
                  lastSelected: new Date(),
                  lastSelectedBy: session.userInOrgId,
                } as any)
              }
            }}
          >
            {/** Don't show anything while not ready. This is to prevent excessive re-renders. */}
            {!matterStore.finishLoad ? null : (
              <>
                {recentMatters.length > 0 ? (
                  <OptGroup
                    key={matterRandomPrefixKey}
                    label={<strong>My Recently Selected Matters</strong>}
                  >
                    {recentMatters.map((m, index) => {
                      return (
                        <Option
                          key={`${matterRandomPrefixKey}-${m.id}`}
                          label={`${m.file} ${m.description}`}
                          search={`${m.file} ${m.description}`}
                          value={m.id}
                        >
                          <>{m.file}</> {m.description}
                        </Option>
                      )
                    })}
                  </OptGroup>
                ) : null}
                {clientMatterArray.map((e) => {
                  const client = e[0]
                  const matters = Array.from(e[1].values())

                  return (
                    <OptGroup key={`${clientRandomPrefixKey}-${client.id}`} label={client.name}>
                      {matters.map((c, index) => {
                        {
                          return (
                            <Option
                              key={`${clientRandomPrefixKey}-${client.id}-${c.id}`}
                              label={`${c.file} ${c.description}`}
                              search={`${c.file} ${c.description}`}
                              value={c.id}
                            >
                              <>{c.file}</> {c.description}
                            </Option>
                          )
                        }
                      })}
                    </OptGroup>
                  )
                })}
              </>
            )}
          </Select>
        </Form.Item>
      )
    }

    if (matterStore.finishLoad) {
      return (
        <>
          <div>{matterStore.finishLoad}</div>
          <ChildSelect {...props} />
        </>
      )
    } else {
      return (
        <>
          <div>{matterStore.finishLoad}</div>
          <Skeleton.Input active size={"small"} style={{ width: 80 }} />
        </>
      )
    }
  }
)
