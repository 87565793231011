import { ReactNode } from "react"
import { theme } from "../styles/styles"

export interface HeaderSubheaderProps {
  title: string | ReactNode
  subtitle: string | ReactNode
  action?: ReactNode
}

export const HeaderSubheader = (props: HeaderSubheaderProps) => {
  const { title, subtitle, action } = props
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        marginTop: 96,
        marginBottom: 96,
      }}
    >
      <h2 style={{ color: theme.gray[700], fontWeight: 600, marginBottom: 4 }}>{title}</h2>
      {/* There are no files being shared */}
      <h4 style={{ color: theme.gray[600], marginBottom: 24 }}>
        {subtitle}
        {/* Share files with end-to-end encryption and automatic compression. */}
      </h4>
      <div style={{ display: "flex", justifyContent: "center" }}>{action}</div>
    </div>
  )
}
