import { action, makeObservable, observable } from "mobx"

export class UploadStore {
  files: {
    id: string
    name: string
    size: number
    lastModified
    type
    arrayBuffer
    slice
    stream
    text
  }[] = []

  progress = 0

  locked = false

  constructor() {
    makeObservable(this, {
      files: observable,
      setFiles: action,
      locked: observable,
      setLocked: action,
      progress: observable,
      setProgress: action,
      clear: action,
    })
  }

  clear = (): void  => {
    this.progress = 0
    this.locked = false
    this.files = []
  }

  setProgress = (value: number): void  => {
    this.progress = value
  }

  setLocked = (value: boolean): void  => {
    this.locked = value
  }

  setFiles = (rows: any[]): void  => {
    this.files = [...rows]
  }
}
